
export const cpfHide = (value) => {
    if (value != null) {
      const digitsOnly = value.replace(/\D+/g, ''); // Remova todos os caracteres não numéricos
      const firstThreeDigits =  digitsOnly.substr(0, 3); // Retorna os três primeiros dígitos
      return `${firstThreeDigits}.xxx.xxx-xx`;
    }
    return '';
  }



