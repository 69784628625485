export const telefoneMask = (value) => {
  
    if (value != null && value.replace(/[^0-9]+/g, '').length <= 10){
      return value.replace(/[^0-9]+/g, '')
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d{4})(\d{4})/,"($1) $2-$3")
    //.replace(/(\d{4})(\d)/, "$1-$2")
    //.replace(/(-\d{4})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
  }else if (value != null && value.replace(/[^0-9]+/g, '').length >= 11 && value.replace(/[^0-9]+/g, '').includes('0800')){
    return value.replace(/[^0-9]+/g, '')
  .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
  .replace(/(\d{4})(\d{3})(\d)/,"$1-$2-$3")
  .replace(/(-\d{4})\d+?$/, '$1').substring(0,13) // captura os dois últimos 2 números, com um - antes dos dois números
  }else if (value != null && value.replace(/[^0-9]+/g, '').length === 11 ){
    return value.replace(/[^0-9]+/g, '')
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/,"($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
  }
  return value !=null ? value.replace(/[^0-9]+/g, '') : value
  }