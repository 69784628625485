import { withStyles } from '@mui/styles';
import { Radio, FormControlLabel, createTheme, ThemeProvider } from '@mui/material';

// Estilos personalizados para o Radio
const CustomRadio = withStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    '&$checked': {
      color: '#fed000', // Cor amarela quando selecionado
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

// Criação do tema personalizado com a fonte Montserrat
const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
  },
});

// Componente de radio button personalizado
const RadioButton = ({ value, label, checked, onChange }) => (
  <ThemeProvider theme={theme}>
    <FormControlLabel
      value={value}
      control={<CustomRadio checked={checked} onChange={onChange} />}
      label={label}
    />
  </ThemeProvider>
);

export default RadioButton;
