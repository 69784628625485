import React, { useState } from 'react';
import { Button, Grid, Modal, TextField, Typography, FormControl, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';
import { cpfMask } from '../utils/cpfMask';
import { Cadastrar } from '../services/Api';
import '../App.css'
import { telefoneMask } from '../utils/telefoneMask';
import RadioButton from '../utils/radioButton';
import SwitchToogle from '../utils/Switch';
import CheckBox from '../utils/CheckBox';
import '@fontsource/montserrat';
/* import { DatePicker} from '@mui/lab';
import '@mui/lab/DatePicker';  */
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DatePicker }from '@mui/x-date-pickers/DatePicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Navigate} from 'react-router-dom'


const FormPage = () => {
    const [cpf, setCPF] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [aceitatermos, setAceitaTermos] = useState('');
    const [isCPFValid, setIsCPFValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isAceitaTermosValid, setIsAceitaTermosValid] = useState('');
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [openSuc, setOpenSuc] = useState(false);
    const [touched, setTouched] = useState(false);
    const [isMilitar, setIsMilitar] = useState(false);
    const [hasServico, setHasServico] = useState(true);
    const [isAuto, setIsAuto] = useState(false);
    const [vencimento, setVencimento] = useState(null);
    const [isResi, setIsResi] = useState(false);
    const [isOdonto, setIsOdonto] = useState(false);
    const [isFAM, setIsFAM] = useState(false);
    const [isFamilia, setIsFamilia] = useState(false);
    const [isBike, setIsBike] = useState(false);
    const [isPet, setIsPet] = useState(false);
    const [isFianca, setIsFianca] = useState(false);
    const [isDecessos, setIsDecessos] = useState(false);
    const [isCelular, setIsCelular] = useState(false);
    const [isConsorcio, setIsConsorcio] = useState(false);
    const [isAceitaTermos, setIsAceitaTermos] = useState(''); // Gabriel
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleOpen = (errorMessage) => {
        setError(errorMessage);
        setOpen(true);
    };
    const handleOpenSuc = (errorMessage) => {
        setError(errorMessage);
        setOpenSuc(true);
    };
    const label = { inputProps: { 'aria-label': 'Switch' } };
    const handleServicoOk = () => {
        setHasServico(true);
    };
    const handleNotServico = () => {
        setHasServico(false);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseSuc = () => {        
        setIsSubmitted(true);
        setOpenSuc(false);
    };
    const handleAction = () => {
        // Ação a ser realizada ao fechar a modal de erro, se necessário
        handleClose();
    };

    const handleCPFChange = (event) => {
        const { value } = event.target;
        setCPF(value);
    };

    const handleNameChange = (event) => {
        const { value } = event.target;
        setName(value);
    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    };
    const handleTelefoneChange = (event) => {
        const { value } = event.target;
        setTelefone(value);
    };
    const handleBlurTel = () => {
        setTouched(true);
    };
    const handleMilitarChange = (event) => {
        const value = event.target.value;
        setIsMilitar(value === "Sim"); // Define o estado com base no valor selecionado
    };
    const handleServicoChange = (event) => {
        const value = event.target.value;
        setHasServico(value === "Sim"); // Define o estado com base no valor selecionado
    };
    const handleAceitaTermosChange = (event) => {
        const { value } = event.target;
        setAceitaTermos(value);
    };

    const handleDateChange = (date) => {
        setVencimento(date);
    };

    const validateCPF = () => {
        // Remove caracteres especiais do CPF
        const cleanedCPF = cpf.replace(/\D/g, '');

        // Verifica se o CPF possui 11 dígitos
        if (cleanedCPF.length !== 11) {
            setIsCPFValid(false);
            return;
        }

        // Verifica se todos os dígitos são iguais (CPF inválido)
        if (/^(\d)\1+$/.test(cleanedCPF)) {
            setIsCPFValid(false);
            return;
        }

        // Calcula os dígitos verificadores do CPF
        let sum = 0;
        let remainder;

        for (let i = 1; i <= 9; i++) {
            sum += parseInt(cleanedCPF.substring(i - 1, i)) * (11 - i);
        }

        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) {
            remainder = 0;
        }

        if (remainder !== parseInt(cleanedCPF.substring(9, 10))) {
            setIsCPFValid(false);
            return;
        }

        sum = 0;
        for (let i = 1; i <= 10; i++) {
            sum += parseInt(cleanedCPF.substring(i - 1, i)) * (12 - i);
        }

        remainder = (sum * 10) % 11;

        if (remainder === 10 || remainder === 11) {
            remainder = 0;
        }

        if (remainder !== parseInt(cleanedCPF.substring(10, 11))) {
            setIsCPFValid(false);
            return;
        }

        setIsCPFValid(true);
    };

    const handleBlur = () => {
        validateCPF();
    };

    const validateName = () => {
        // Verifique se o nome não contém números usando uma expressão regular
        const containsNumbers = /\d/.test(name);
        setIsNameValid(!containsNumbers);
    };

    const validateEmail = () => {
        // Expressão regular para validar o formato do e-mail
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Verifica se o e-mail corresponde ao formato esperado
        if (regex.test(email)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
         
    };
    const validateAceitaTermos = () => {
        if (!isAceitaTermos) {
          setIsAceitaTermos(true)
        } else {
            setIsAceitaTermos(false)
        }
       return;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Executa as validações
        validateCPF();
        validateName();
        validateEmail();
        validateAceitaTermos();

        // Verifica se todos os campos são válidos
        if (isCPFValid && isNameValid && isEmailValid && isAceitaTermos) {
            // Todos os campos são válidos, faça o processamento adicional aqui
            console.log('aceita',isAceitaTermos)  
            const cadastrar = { 
                nome: name,
                email: email, 
                cpf: cpf, 
                telefone: telefone, 
                militar: isMilitar == true? '1':'0',
                auto: isAuto == true? '1':'0',
                residencia: isResi == true? '1':'0',
                odonto: isOdonto == true? '1':'0', 
                fam: isFAM == true? '1':'0',
                familia: isFamilia == true? '1':'0',
                bike: isBike == true? '1':'0',
                pet: isPet == true? '1':'0',
                fianca: isFianca == true? '1':'0',
                decessos: isDecessos == true? '1':'0',
                celular: isCelular == true? '1':'0',
                consorcio: isConsorcio == true? '1':'0',
                AceitaTermos: isAceitaTermos == true? '1':'0', // Gabriel 
                vencimentoAuto: vencimento == null ? "" : vencimento
            };    

            try {

                const retorno = await Cadastrar(cadastrar);
                if(retorno?.error){    
                   throw retorno.error;    
                }
                else{
                    handleOpenSuc(retorno.msg);
                }
                
            } catch (error) {
                handleOpen(error);
            }
        } else {
            // Pelo menos um campo é inválido
            handleOpen('Você deve aceitar os termos para continuar.');
        }
    };

    if (isSubmitted) {
        return <Navigate to="/" replace />;
      }

    return (
        <div className='App'>
            <div className='overlay'>
            <form onSubmit={handleSubmit}>

                <Grid container spacing={4}>
                    
                    <Grid item xs={12} >
                        <h2>Cadastro Sorteio</h2>
                    </Grid>
                    <Grid item xs={9} >
                        <TextField
                            className='form-field'
                            required
                            label="CPF"
                            autoComplete='off'
                            value={cpfMask(cpf)}
                            onChange={handleCPFChange}
                            onBlur={handleBlur}
                            placeholder="Digite o CPF"                           
                            variant="outlined"
                            fullWidth
                            error={!isCPFValid && cpf !== ''}
                            helperText={!isCPFValid && cpf !== '' ? 'O CPF é inválido.' : ''}
                            inputProps={{ style: { fontSize: '25px', }, inputMode: 'numeric',  }}                        
                        />
                    </Grid>

                    <Grid item xs={9} >
                        <TextField
                            className='form-field'
                            required
                            label="Nome"
                            value={name}
                            type="text"
                            autoComplete='Text'
                            onChange={handleNameChange}
                            onBlur={validateName}
                            placeholder="Digite o nome"
                            variant="outlined"
                            fullWidth
                            error={!isNameValid && name !== ''}
                            helperText={!isNameValid && name !== '' ? 'O nome não pode conter números.' : ''}
                            inputProps={{ style: { fontSize: '25px', }, }}
                        />
                    </Grid>
                    <Grid item xs={9} >
                        <TextField
                            className='form-field'
                            required
                            label="Telefone"
                            value={telefoneMask(telefone)}
                            onChange={handleTelefoneChange}
                            onBlur={handleBlurTel}
                            placeholder="Digite o telefone"
                            variant="outlined"                            
                            fullWidth
                            error={touched && telefone.length < 15}
                            helperText={touched && telefone.length < 15 ? 'Favor digitar um telefone válido' : ''}
                            inputProps={{ style: { fontSize: '25px', },inputMode: 'numeric',  }}                        
                        />
                    </Grid>
                    <Grid item xs={9} >
                        <TextField
                            className='form-field'
                            required
                            label="E-mail"
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={validateEmail}
                            placeholder="Digite o e-mail"
                            variant="outlined"
                            fullWidth
                            error={!isEmailValid && email !== ''}
                            helperText={!isEmailValid && email !== '' ? 'O e-mail é inválido.' : ''}
                            inputProps={{ style: { fontSize: '25px', }, }}
                        />
                    </Grid>
                    <Grid item md={9} xs={10}  display={'flex'} >
                        <FormControl style={{ fontSize: '25px', color: '#FFFFFF' }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={isMilitar ? 'Sim' : 'Não'}
                                onChange={handleMilitarChange}
                            >É Militar?
                                <FormLabel
                                    style={{ marginRight: '50px' }} // Adiciona margem (espaço) entre o texto e o radio button   
                                    label="É Militar?"
                                />
                                <RadioButton
                                    value="Sim"
                                    label="Sim"
                                    checked={isMilitar}
                                    onChange={handleMilitarChange}
                                />
                                <RadioButton
                                    value="Não"
                                    label="Não"
                                    checked={!isMilitar}
                                    onChange={handleMilitarChange}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        md={9}
                        xs={10}
                        style={{ color: '#FFFFFF' }}
                        display={'flex'}
                    >
                        
                        
                            <Typography variant='h4'>Qual o serviço de interesse?</Typography>
                                
                    </Grid>
                        <Grid item md={9} sm={10.5} xs={11} style={{ color: '#FFFFFF' }} >
                            <Grid item md={3} sm={6} xs={9} sx={{color: '#FFFFFF', marginBottom:"15px"}}
                                display={'flex'}>
                                <Typography variant="h5"> Serviços: </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isAuto} onChange={() => { setIsAuto(!isAuto) }} />}
                                        label="Seguro Auto"
                                    />                                   
                                </Grid>
                                
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isResi} onChange={() => { setIsResi(!isResi) }} />}
                                        label="Seguro Residência"
                                    />
                                </Grid>
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isOdonto} onChange={() => { setIsOdonto(!isOdonto) }} />}
                                        label="Plano Odontológico"
                                    />
                                </Grid>
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isCelular} onChange={() => { setIsCelular(!isCelular) }} />}
                                        label="Seguro Celular"
                                    />
                                </Grid>
                                <Grid item md={6} xs={11} sm={11}  display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isConsorcio} onChange={() => { setIsConsorcio(!isConsorcio) }} />}
                                        label="Consórcio"
                                    />
                                </Grid>
                                {/* <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isFAM} onChange={() => { setIsFAM(!isFAM) }} />}
                                        label="FAM"
                                    />
                                </Grid>
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isFamilia} onChange={() => { setIsFamilia(!isFamilia) }} />}
                                        label="FAM Familía"
                                    />
                                </Grid> 
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isBike} onChange={() => { setIsBike(!isBike) }} />}
                                        label="Seguro Bike"
                                    />
                                </Grid>*/}
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isPet} onChange={() => { setIsPet(!isPet) }} />}
                                        label="Seguro Pet"
                                    />
                                </Grid>
                                <Grid item md={6} xs={11} sm={11} display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isFianca} onChange={() => { setIsFianca(!isFianca) }} />}
                                        label="Fiança Locatícia"
                                    />
                                </Grid>
                                <Grid item md={6} xs={11} sm={11}  display={'flex'}>
                                    <FormControlLabel
                                        control={<CheckBox checked={isDecessos} onChange={() => { setIsDecessos(!isDecessos) }} />}
                                        label="Seguro Decessos"
                                    />
                                </Grid>
                                   
                                </Grid> 
                             <Grid style={{marginTop:'10%'}}>
                            <Grid item md={12} xs={11} sm={20} display={'flex'}>
                                <FormControlLabel
                                        control={<CheckBox checked={isAceitaTermos} onChange={() => { setIsAceitaTermos(!isAceitaTermos) }} />}
                                        label={
                                            <Typography variant= 'h6'> Ao participar do sorteio, autorizo o envio de informações sobre promoções e produtos.</Typography> 
                                        }
                                        />
                                                              
                                </Grid>
                                </Grid>

                        </Grid>
                  
                    
                </Grid>
                <Button type="submit"  >
                    Enviar
                </Button>
            </form>
            {/* Modal de erro */}
            <Modal open={open} onClose={handleClose}>
                <div className="modal-container">
                    <div className="modal-content">
                        <Typography variant="h1">Erro</Typography>
                        <Typography variant="body1">{error}</Typography>
                        <Button onClick={handleAction}>Fechar</Button>
                    </div>
                </div>
            </Modal>
            {/**/}
            <Modal open={openSuc} onClose={handleCloseSuc}>
                <div className="modal-container">
                    <div className="modal-content">
                        <Typography variant="h1">Parabéns!!!!</Typography>
                        <Typography variant="body1">{error}</Typography>
                        <Button onClick={handleCloseSuc}>Fechar</Button>
                    </div>
                </div>
            </Modal>
        </div>
        </div>
    );
};

export default FormPage;
