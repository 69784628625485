import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Inicial from './pages/Inicial';
import Formulario from './pages/Cadastro';
import Sorteio from './pages/Sorteio';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Inicial />} />
        <Route exact path="/Cadastro" element={<div className='App'><Formulario /></div>} />
        <Route exact path="/sorteio" element={<div className='App'><Sorteio /></div>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
