import React from 'react';
import { withStyles } from '@mui/styles';
import { Checkbox, FormControlLabel } from '@mui/material';

const ColorCheckbox = withStyles({
  root: {
    color: '#FFFFFF',
    '&$checked': {
      color: '#fed000',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CheckBox = ({ checked, onChange, label }) => {
  return (
    <FormControlLabel
      control={<ColorCheckbox checked={checked} onChange={onChange} />}
      label={label}      
    />
  );
};

export default CheckBox;