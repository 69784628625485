import React from 'react';
import '../App.css';
import {Link} from 'react-router-dom'
import logo1 from '../imagens/logoProseg.png';
/* import logo2 from '../imagens/etapa_onca_pintada.svg';
import logo3 from '../imagens/corrida_poupex.svg';
import logo4 from '../imagens/onça.png' */

const Header = () => {
  return (
    <header className="header">
      {/* <Link to="/">
        <img className="logo" src={logolocal} alt="Logo Evento" />
      </Link> */}
      {/* <Link to="/">
        <img className="logo" src={logo2} alt="Logo 2" style={{ marginRight: '30px', marginBottom: '15px' }} />
      </Link>
      <Link to="/">
        <img className="logo" src={logo4} alt="Logo 3" style={{ marginRight: '30px', marginBottom: '15px' }} />
      </Link> */}
      <Link to="/">
        <img className="logo" src={logo1} alt="Logo Proseg" style={{ marginLeft: '30px'}} />
      </Link>
    </header>
  );
};

export default Header;