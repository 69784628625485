import React, { useState, useEffect } from 'react';
import { Button, Grid, Modal,Typography,Container,Box} from '@mui/material';
import { Link } from 'react-router-dom';
import { Listar } from '../services/Api';
import {BsPeopleFill}  from "react-icons/bs"
import {Navigate} from 'react-router-dom'
import logolocal from '../imagens/logo_escuro.webp'
import logo from '../imagens/logo_fhe_poupex.svg'
import simbolo from '../imagens/simbolo.svg'

const Inicial = () => {
  const [count, setCount] = useState(0);
  const [openSuc, setOpenSuc] = useState(false);
  const [next,setNext] = useState(false)

  const handleOpenSuc = () => {    
    setOpenSuc(true);
};

  const handleCloseSuc = () => {
    setOpenSuc(false);
};

const handleButton = () => { 
  setNext(true)  ; 
  
};




  const getCount = async () => {
    const counter = await Listar();
    //console.log(counter)
    setCount(counter);
  }
  useEffect(()=>{
    getCount();
  },[]);
  
  useEffect(() => {
    // Atualiza o contador a cada segundo
    const timer = setInterval(() => {      
      getCount();
    }, 5000);

    // Limpa o timer quando o componente é desmontado
    return () => clearInterval(timer);
  }, []);



if(next){
  return <Navigate to="/Cadastro" replace />
}
  return (
   
    <Grid className='inicial'>     
    <Grid style={{marginTop:'90px'}}>
  <Typography variant="h1" style={{fontFamily:'Arial'}}>SORTEIO</Typography>          
    <Grid >
    <Grid style={{marginBottom:'25px'}}>
    <img src={simbolo} alt='Onça' ></img> 
    <Container maxWidth='xl' className="text-center">
    <Box sx={{ mb: 4 }}>
<Grid container justifyContent="space-between" spacing={3} style={{marginTop:'40px'}}>
    <Grid item md='6' xl='6' sm='12' xs='12'>
        <Button      
         onClick={handleOpenSuc}>
          Regulamento
        </Button>
        </Grid>
        <Grid item md='6' xl='6' sm='12' xs='12'>
        <Button    
         onClick={handleButton}>
          Cadastre-se
        </Button> 
      </Grid>
      </Grid>
      </Box>
      </Container>
    <img src={logo} alt = 'logo' ></img>
    </Grid>
    {/* <Grid style={{marginTop:"50px"}}>
      <Typography variant = "h5" style={{color:'#FFFFFF', textAlign:'initial', marginLeft:'5px'}}>
      Os prêmios sorteados serão:
      </Typography>
      <Typography style={{color:'#FFFFFF', textAlign:'initial', marginTop:"20px", marginLeft:'5px'}}>
      1 Echo 4ª geração smart speaker com Alexa;
      </Typography>
      <Typography style={{color:'#FFFFFF', textAlign:'initial', marginLeft:'5px'}}>
      1 Echo show 5 2ª geração smart speaker tela de 5,5;
      </Typography>
      <Typography style={{color:'#FFFFFF', textAlign:'initial', marginLeft:'5px'}}>
      1 Seguro Residencial com duração de 1 ano.
      </Typography>
    </Grid> */}
    <div className='contador' ><BsPeopleFill style={{marginRight:'10px'}}></BsPeopleFill>{count}</div>
      </Grid>    
      
      <Modal open={openSuc} onClose={handleCloseSuc}>
     <div className="modal-container">
         <div className="modal-content-inicial">
         <Typography variant="h1">Regulamento</Typography>
         <div className="scrollable-content">    
         <div className="modal-body">        
            {/*<Typography variant="body1">01. Somente pessoas que preencheram o cupom do sorteio durante o evento Portões Abertos, Base Aérea de Brasília, realizado no dia 03 de setembro de 2023, bem como aqueles cadastrados pelo site, estarão participando.</Typography>*/}
            <Typography variant="body1">01. O sorteio ocorrerá no dia 31/10/2023 à partir de 18hrs (horário de Brasília) por meio do canal da Proseg Administradora e Corretora de Seguros no Instagram, cujo perfil é o @prosegseguros.</Typography>
            <Typography variant="body1">02. Os prêmios do sorteio serão 1 Echo Dot (3a Geração), 1 Relógio Smartwatch Watch LS05 Haylou e 2 sorteios de R$500,00 cada.</Typography>
            <Typography variant="body1">03. Cada sorteado será contemplado com 1 (um) item dos prêmios citado acima.</Typography>
            <Typography variant="body1">04. Os nomes sorteados serão submetidos a uma breve auditoria para conferência de informações (nome, cpf, contato e demais tópicos pedidos no momento do cadastro para o sorteio).</Typography>
            <Typography variant="body1">05. Ao participar do sorteio, o concorrente autoriza o envio de informações sobre promoções e produtos da Proseg e Poupex. </Typography>
            <Typography variant="body1">06. O concorrente participará com um único cadastro, não importando se feito pelo site ou pelo preenchimento do cupom, nosso sistema eliminará duplicatas de cadastro.</Typography>
            <Typography variant="body1">07. O sorteio vale-se do Instagram como plataforma de mídia, não tendo vínculo algum com esta rede social. Ao Instagram não cabe responsabilidade alguma.</Typography>
            <Typography variant="body1">08. O sorteio é de total responsabilidade da Proseg Administradora e Corretora de Seguros, a Fundação Habitacional do Exército (FHE) e a Poupex não tem qualquer vínculo de responsabilidade sobre o sorteio.</Typography>
            <Typography variant="body1">09. A Proseg Administradora e Corretora de Seguros entrará em contato com os ganhadores por meio dos dados fornecidos no cupom. É de responsabilidade do ganhador responder e passar os dados corretos para a entrega da premiação.</Typography>
            <Typography variant="body1">10. A Proseg Administradora e Corretora de Seguros reserva-se o direito de alterar quaisquer itens deste regulamento visando o bom desenvolvimento da ação.</Typography>
            <Typography variant="body1">11. Qualquer situação não prevista neste regulamento será resolvida pela equipe da Proseg Administradora e Corretora de Seguros em até 48 horas a partir do ocorrido.</Typography>
            <Typography variant="body1">12. Este sorteio tem caráter exclusivamente promocional e não implica qualquer modalidade de pagamento por parte dos participantes, não sendo, portanto, necessária a aquisição de nenhum produto, bem ou serviço.</Typography>
            <Typography variant="body1">13. Os participantes declaram ter lido e estarem de acordo com o presente regulamento do concurso e de suas condições.</Typography>
            <Typography variant="body1">14. Os sorteados terão 30 dias após sorteio para resgatar o prêmio.</Typography>
            </div>
         </div>
             <Button onClick={handleCloseSuc}>Fechar</Button>
         </div>
     </div>
 </Modal>
    </Grid>   
    </Grid> 
  );
};

export default Inicial;