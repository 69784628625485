import React from 'react'
import logo from '../imagens/logoProseg.png'

const Footer = ()=> {

return (   
      <footer id="footer" style={{backgroundColor:"#000000"}}>
        <div className="div-footer" style={{textAlign:"center"}}>
        <div >
           <img src={logo} alt='logo' height={"60px"} style={{marginTop:'10px'}}/>
          </div>
          <div style={{fontSize:9,marginRight:"15px", marginLeft:"15px",color:'#FFFFFF'}}>          
                Copyright © <span id="id_data">2023</span> Proseg. Todos os direitos reservados.
          </div>
          

          </div>    
         
      </footer>
      
  )
}
export default Footer;