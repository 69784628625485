import Api from './Conection'
import {IDsorteio} from '../consts/Consts'


export const Cadastrar = async (cadastrar) => {
    Api.defaults.headers.common = { "Content-Type":"application/json"};  
    console.log('cadastrar',cadastrar)  
      const response = await Api.post(`/api/cadastro/novo`, {
        Nome: cadastrar.nome,
        Email: cadastrar.email,
        Cpf: cadastrar.cpf,
        Telefone:cadastrar.telefone,
        Militar:cadastrar.militar,
        Auto:cadastrar.auto,
        Residencia:cadastrar.residencia,
        Odonto:cadastrar.odonto,
        Fam:cadastrar.fam,
        Familia:cadastrar.familia,
        Bike:cadastrar.bike,
        Pet:cadastrar.pet,
        Fianca:cadastrar.fianca,
        Decessos:cadastrar.decessos,
        Celular: cadastrar.celular,
        Consorcio: cadastrar.consorcio,
        VencimentoAuto:cadastrar.vencimentoAuto,
        AceitaTermos: cadastrar.AceitaTermos,
        numSorteio:IDsorteio
      });
      
      return response.data;
    };

    export const Listar = async () => {
    
        const response = await Api.get(`api/cadastro/listar/${IDsorteio}`);
    
        return response.data;
      };

      export const ListarPorId = async (id) => {
    
        const response = await Api.get(`api/cadastro/${id}`);
    
        return response.data;
      };

      export const Sortear = async (teste) => {
    
        const response = await Api.get(`api/sorteio/sortear?SorteioID=${IDsorteio}&teste=${teste}`);
    
        return response.data;
      };

      export const ListarSorteado = async () => {
    
        const response = await Api.get(`api/sorteio/listar/${IDsorteio}`);
    
        return response.data;
      };