import React from 'react';
import { withStyles } from '@mui/styles';
import { Switch, FormControlLabel } from '@mui/material';

const CustomSwitch = withStyles({
  switchBase: {
    color: '#ffffff',
    '&$checked': {
      color: '#19334D', 
    },
    '&$checked + $track': {
      backgroundColor: '#19334D', 
    },
  },
  checked: {},
  track: {},
})(Switch);

// Componente de radio button personalizado
const SwitchToogle = ({ value, label, checked, onChange }) => (
  <FormControlLabel
    value={value}
    control={<CustomSwitch checked={checked} onChange={onChange} />}
    label={label}
  />
);
export default SwitchToogle;