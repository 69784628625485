import React, { useEffect, useState } from 'react';
import { Button, Grid, Modal, Table, TableHead, TableBody, TableRow, TableCell,Typography,FormControlLabel } from '@mui/material';
import {ListarSorteado, Sortear } from '../services/Api';
import { cpfHide } from '../utils/CpfAnonimacao';
import CheckBox from '../utils/CheckBox';
import sorteio from '../imagens/sorteio.gif'

const Sorteio = () => {
  const [resultado, setResultado] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showGif, setShowGif] = useState(true);
  const [teste, setTeste] = useState(false);
  const [sorteados, setSorteados] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

 /*  const animProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  }); */
  const handleOpen = (errorMessage) => {
    setError(errorMessage);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAction = () => {
    // Ação a ser realizada ao fechar a modal de erro, se necessário
    handleClose();
  };

  const Sorteados = async ()=>{
    try{
    const resultado = await ListarSorteado();
    setSorteados(resultado);
    }
    catch{
      
    }
  }
  useEffect(() => {
    Sorteados()    
  }, [modalOpen]);
  useEffect(() => {
    if (modalOpen) {
      if(teste){setShowGif(false);}
      else{
      setTimeout(() => {
        setShowGif(false);
      }, 5000); // Tempo de exibição do GIF em milissegundos (neste caso, 5 segundos)
    }}
  }, [modalOpen]);
  const handleSorteio = async () => {
    // Chamar o endpoint de sorteio e obter o resultado
    try {
        const retorno = await Sortear(teste === true ? "1" : "0");  

        if(retorno.error){
          throw retorno.error;
        }
        
        
      // Simulando uma chamada assíncrona com um tempo de espera
      await new Promise((resolve) => setTimeout(resolve, 100));

      // Obter o resultado do sorteio
        
        // Atualizar o estado do resultado
        setResultado(retorno);
        // Abrir a modal
        setModalOpen(true);
      
    } catch (error) {        
        handleOpen(error);
    }
    
   
        
  };

  const closeModal = () => {
    // Fechar a modal e voltar à tela inicial
    setModalOpen(false);
    setShowGif(true);
  };
console.log("retorno",sorteados)
  return (
    <div className='App'>
    <div className='overlay'>
      <Grid marginTop={'250px'}>
    <Grid>
      <h1 style={{ color: "white",  }}>Sorteados : </h1>
      <Grid>
      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell>Posição Sorteada</TableCell>
            <TableCell>Nome</TableCell>            
            <TableCell>CPF</TableCell>            
          </TableRow>
        </TableHead>
        <TableBody>
          {sorteados.map((item, index) => (
            <TableRow key={item?.id}>
              <TableCell>{index + 1}º</TableCell>
              <TableCell>{item?.nome}</TableCell>             
              <TableCell>{cpfHide(item?.cpf)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>    
    </Grid> 
    <Grid item md={12} xs={12} sm={11} display={'flex'} required>
    <FormControlLabel
        control={<CheckBox checked={teste} onChange={() => { setTeste(!teste) }} />}
        label={
            <span style={{ fontSize: '12px', position: 'relative' }}>
                <span style={{ color: 'darkblue', position: 'absolute', top: '-9px', left: '-25px' }}>Testar</span>
            </span>
        }
    />
</Grid>

      <Button
        type="submit"
        variant="contained"
        color="primary"       
        onClick={handleSorteio}
      >
        Realizar Sorteio
      </Button>
     {/*  <animated.div style={animProps}>
        {resultado && <p>{resultado}</p>}
      </animated.div> */}
      <Modal open={modalOpen} onClose={closeModal}>
        <div className="modal-container">
          <div className="modal-content">
          {showGif ? (
                <img src={sorteio} alt="Sorteio" />
              ) : (
                <>
            <h2>Resultado do Sorteio</h2>
            <p className='Sorteio'> Ganhador: {resultado.nome}</p>
            <p>Cpf: {cpfHide(resultado.cpf)}</p>          
            <Button variant="contained" onClick={closeModal}>
              OK
            </Button>
            </>)}
          </div>
        </div>
      </Modal>
       {/* Modal de erro */}
       <Modal open={open} onClose={handleClose}>
        <div className="modal-container">
          <div className="modal-content">
            <Typography variant="h2">Erro</Typography>
            <Typography variant="body1">{error}</Typography>
            <Button onClick={handleAction}>Fechar</Button>
          </div>
        </div>
      </Modal>
    </Grid>
    </Grid>
    </div>
    </div>
  );
};

export default Sorteio;
