//API
export const URL_API_BASE_DEV = "http://des.api.sorteio.proseg.com.br";
//API LOCAL
export const URL_API_BASE_LOCAL = "https://localhost:3000";
//API HOM
export const URL_API_BASE_HOM = "http://hom.api.sorteio.proseg.com.br";
//API PROD
export const URL_API_BASE_PROD = "https://api.sorteio.proseg.com.br";

//Numero do sorteio
export  const IDsorteio = "3";

